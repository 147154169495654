import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Input, Image, Toast, Modal } from 'antd-mobile'
import ImageUser from '../../images/user.png'
import ImageRobot from '../../images/robot.png'
import './index.scss'
import clsx from 'clsx'
import React, { useCallback, useRef, useState } from 'react'
import { askChatSingle } from '../../api/chat'
import { marked } from 'marked'

type ContentItem = {
  role:string
  content:string
}

const content:ContentItem[] = []

function HomePage() {
  const [ editable, setEditable ] = useState<boolean>(true)
  const [ userContent, setUserContent ] = useState<string>('')
  const [ contentList, setContentList ] = useState<ContentItem[]>(content)
  const refTalk = useRef<HTMLDivElement>(null)
  const refInput = useRef<React.ElementRef<typeof Input>>(null)

  const handleConfirm = useCallback(async() => {
    if (!userContent) {
      Modal.alert({
        content: '请输入内容'
      })
      refInput.current?.focus()
      return
    }

    setContentList([
      ...contentList,
      { role: 'user', content: userContent },
      { role: 'assistang', content: '等待回复中...' }
    ])
    setUserContent('')
    setTimeout(() => {
      refTalk.current?.scrollTo({ top: refTalk.current.scrollHeight })
    }, 0)
    setEditable(false)

    const res = await askChatSingle(userContent)
    if (res instanceof Error) {
      Toast.show('请求异常，请重试')
      setEditable(true)
      return
    }
    const { data: choices } = res
    const content = marked.parse(choices[0].message.content.trim())
    setContentList([
      ...contentList,
      { role: 'user', content: userContent },
      { ...choices[0].message, content }
    ])
    setEditable(true)
  }, [ contentList, userContent ])
  
  return <HelmetProvider>
    <Helmet>
      <title>Chatgpt Demo</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0" />
    </Helmet>
    <div className='pg-home'>
      <div className='pg-home__header'>AI Chat</div>
      <div className='pg-home__talk' ref={refTalk}>
        {contentList.map((item, i) => <div className={clsx('pg-home__talk-item', {
          user: item.role === 'user',
          assistant: item.role === 'assistant'
        })} key={i}>
          <Image
            className=''
            src={item.role === 'user' ? ImageUser : ImageRobot}
            width={32}
            height={32}
            fit='contain'
            style={{ borderRadius: 8, border: 'solid 1px #ccc', padding: '2px' }}
          />
          <div className='pg-home__talk-item-content' dangerouslySetInnerHTML={{ __html: item.content }} />
        </div>)}
      </div>
      <div className='pg-home__input'>
        <Input ref={refInput} placeholder='请输入内容' clearable value={userContent} onChange={setUserContent} onEnterPress={handleConfirm} disabled={!editable} />
      </div>
    </div>
  </HelmetProvider>
}

export default HomePage